






<script>

/**
 * Agent Basic Info component
 */

import { getBrokerageApi } from "@/api/brokerage"
import FeeEditDialog from './agent_fee_edit_dialog.vue'
import FeeSetupDialog from './agent_fee_setup_dialog.vue'

export default {



  props: ['agent'],
  components: {
    FeeEditDialog,
    FeeSetupDialog
  },


  data() {

    return {

      fields: [
        {
          key: "Name",
          sortable: true,
        },
        {
          key: "Code",
          sortable: true,
        },
        {
          key: "Fee",
          sortable: true,
        },
        {
          key : "Action"
        }
      ],

    
      perPage: 50,
      currentPage: 1,
      totalRows: 0,
      fees: [],
      default_fee_items: [], //for new agent
      
    }

  },

  watch: {
    agent: function(newValue, oldValue) {
     
      (oldValue);
      if (!newValue.id) {
        return
      }
      
      this.fees = newValue.fee_items.filter((e) => e.category == 'LISTING')
      this.totalRows = this.fees.length
    },
  },

  methods: {
   

    default_fee_list() {
      let data = {
        page: {
          page: this.currentPage,
          page_size: this.perPage
        },
        categorys:['LISTING']
       
      }
      getBrokerageApi().configApi.agent_default_fees(data).then((res) => {
        if (this.agent.id) {
          return;
        }
        if (res.errCode == 0) {
          this.fees = []
          this.fees = res.data.filter(e=>e.category =='LISTING')
          this.totalRows = this.fees.length
        }
      })
    },

    agent_fee_save(newFee) {
    

      let fee = this.fees.find((e) => e.code == newFee.code)

      if (fee.fixed_type =='FIXED') {
        fee.fixed_fee = fee.amount
      } else {
        fee.fixed_rate = fee.amount
      }

      this.$bvModal.hide('modal-listing-fee-edit-dialog_'+fee.id)
      
    },



    get_fee_items() {
      return this.fees
    },

    open_fee_edit_dialog(fee) {
     
      if (fee.fixed_type =='FIXED') {
        fee.amount = fee.fixed_fee
      } else {
        fee.amount = fee.fixed_rate
      }
      this.$bvModal.show('modal-listing-fee-edit-dialog_'+fee.id)
    },

    add_agent_fee(newFee) {
       
       let fee = {
         fee_code : newFee.code,
         agent_id : this.agent.id,
         fee_amount : newFee.fee_type == 'FIXED' ? newFee.amount : 0,
         fee_rate : newFee.fee_type == 'RATE' ? newFee.amount  / 100.0: 0,
       }

       getBrokerageApi().agentApi.agent_add_fee(fee).then((res) => {
         if (res.errCode == 0) {
           this.$alertify.message("Fee Added");
           this.$bvModal.hide('modal-add-agent-listing-fee-dialog')

           let obj = res.data[0]
           const fr = 
             Object.keys(obj).reduce((acc, k) => {
               acc[k.toLowerCase()] = obj[k];
               return acc;
             }, {});

             this.agent.fee_items.push(fr)
             this.fees.push(fr)

         } else {
           this.$alertify.error("Fee Add  Failed " + res.errCode);
         }
       })
     },


  },

  created() {

  },

  
  beforeUpdated() {

  },

  mounted() {
    
    this.default_fee_list()


  },



};
</script>


<template>
  <div class="row">
    <div class="d-flex align-items-start">
      <div class="flex-grow-1">
        <h5 class="card-title">Tasks</h5>
      </div>
      <div class="flex-shrink-0">

        <b-dropdown right variant="white" toggle-class="btn-link text-dark p-0" menu-class="dropdown-menu-end">
            <template v-slot:button-content>
                <i class="uil uil-ellipsis-h"></i>
            </template>
            <b-dropdown-item v-b-modal.modal-add-agent-listing-fee-dialog>Add Agent Fee</b-dropdown-item>
        </b-dropdown>


                
        <b-modal centerd :id="'modal-add-agent-listing-fee-dialog'" title="Fee" size="lg" hide-footer>
            <FeeSetupDialog :category="'LISTING'" @cancel="$bvModal.hide('modal-add-agent-listing-fee-dialog')"
                @confirm="add_agent_fee" />
        </b-modal>
       
      </div>
    </div>

    <div class="card-body">

      <div class="table-responsive">
        <div class="table align-middle table-nowrap">
          <b-table :items="fees" :fields="fields" :per-page="perPage" responsive="sm" class="table-check">

            <template #cell(Name)="data">
              {{ data.item.name }}
            </template>

            <template #cell(Code)="data">
              {{ data.item.code }}
            </template>

            <template #cell(Fee)="data">
              {{ data.item.fixed_type == 'FIXED' ? data.item.fixed_fee : data.item.fixed_rate * 100 + '%' }}
            </template>

            <template #cell(Action)="data">
              <b-dropdown variant="light" right size="sm">
                <template v-slot:button-content>
                  <i class="uil uil-ellipsis-h"></i>
                </template>
                <b-dropdown-item @click="open_fee_edit_dialog(data.item)">Edit</b-dropdown-item>
              </b-dropdown>
              <b-modal centerd :id="'modal-listing-fee-edit-dialog_'+data.item.id" title="Fee Edit" size="lg" hide-footer>
                <FeeEditDialog :fee="data.item" @cancel="$bvModal.hide('modal-listing-fee-edit-dialog_'+data.item.id)" @confirm="agent_fee_save" />
              </b-modal>

            </template>
          </b-table>
        </div>
      </div>
      <!-- end table responsive -->


    </div>
    <!-- end card body -->



  </div>
  <!-- end row -->
</template>