





<script>

/**
 * Agent Basic Info component
 */

//import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";

import Choices from "choices.js";
import { required } from "vuelidate/lib/validators";
import { uploadFile } from '@/api/misc'


import {getBrokerageApi} from "@/api/brokerage"

import avatar_icon from '@/assets/images/users/avatar-1.jpg'
export default {



    props: {
        agent: {
            type: Object,
            default: () => ({
                fields: {
                    basic_info: {},
                    key_dates      : {
                        key_date_registration :'',
                        key_date_fee_start    : ''
                    }
                }
            })
        },

    },
    components: {
        flatPickr
    },


    data() {

        return {
            avatar: avatar_icon,
            office_choice : {},
            office_list : []
        }

    },

    compute: {

    },

    validations: {


        agent: {
            first_name: { required },
            last_name: { required },
            code: { required },
            phone: { required },
            LWA_userName: { required },
        }


    },

    watch: {
        agent: function(old, newAgentValue) {
            
            if (newAgentValue)  {
                this.avatar = newAgentValue.picture
            }
        }
    },

    methods: {

        update_agent(agent) {
           
            this.office_list.map(e => {
                e.selected = e.value == agent.office_code? true :false
            })
            this.office_choice.setChoices(this.office_list)
        },

        chooseAvatar() {
            this.$refs.avatarFile.click()
        },

        avatarChanged(evt) {
            if (evt.target.files.length <= 0) {
                return
            }
            let formData = new FormData()
            formData.append("file", evt.target.files[0]);
            uploadFile(formData).then((res) => {
                if (res[0].errCode == 200) {
                    this.agent.avatar = res[0].response.url
                    this.avatar = res[0].response.url
                }
            })
        },

        reset() {
            this.$v.$reset();
        },

        validate() {
            this.$v.$touch();
            return this.$v.$invalid
        },

        onOfficeChanged(e) {
            let office = this.office_list.find((o) => o.value == e.detail.value)
            this.agent.office_name = office.label
        }
    },

    created() {

        getBrokerageApi().officeApi.list().then( (res) => {
            this.office_list = []
            res.data.map((f)=>{     
                // let select = this.staff.offices.find((e) => e == f.id) ? true : false
                this.office_list.push({
                    label : f.branch_name,
                    value : f.office_code,
                    office : f,
                    selected : f.office_code == this.agent.office_code ? true : false
                })
            })
         
            this.office_choice.setChoices(this.office_list)
        })

    },

    mounted() {

      
        this.office_choice = new Choices("#choices-office-input", {
            removeItemButton: true,
        });

        this.agent_type_choice = new Choices("#choices-agent-type")

    }

};
</script>


<template>
    <div class="row">

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            01
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                </div>
            </div>
            <div class="card-body">
                <div>
                    <div class="row">
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">First Name</label>
                                <input type="text" class="form-control" v-model="agent.first_name" id="gen-info-name-input"
                                    placeholder="Enter Name" :class="{
                                        'is-invalid': $v.agent.first_name.$error,
                                    }" />
                                <div v-if="$v.agent.first_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.first_name.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-designation-input">Last Name</label>
                                <input type="text" class="form-control" id="gen-info-designation-input"
                                    placeholder="Enter Last Name" v-model="agent.last_name" :class="{
                                        'is-invalid': $v.agent.last_name.$error,
                                    }" />
                                <div v-if="$v.agent.last_name.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.last_name.required">This value is required.</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-designation-input">Agent Type</label>
                                <select class="form-control" v-model="agent.a_status"  name="choices-agent-type" id="choices-agent-type">
                                    <option value="Agent" >Agent</option>
                                    <option value="Licensed Assistant" >Assistant</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-lg-4 text-center">
                            <input ref="avatarFile" class="form-control" @change="avatarChanged" type="file" id="avatarFile"
                                style="opacity: 0;width : 1px; height:1px" />
                            <div class="position-relative d-inline-block mx-auto mt-n5 mb-4">
                                <div class="avatar-lg">
                                    <img :src="agent?agent.picture:avatar" alt="" class="rounded-circle img-thumbnail" />
                                </div>
                                <a href="#" class="d-block position-absolute bottom-0 end-0" @click="chooseAvatar">
                                    <div class="avatar-sm">
                                        <div class="avatar-title rounded-circle bg-light text-primary">
                                            <i class="mdi mdi-pencil"> </i>
                                        </div>
                                    </div>
                                </a>

                            </div>
                        </div>

                    </div>

                    <div class="row">
                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">VIN</label>
                                <input type="text" class="form-control" v-model="agent.code" 
                                    placeholder="Enter Agent VIN" :class="{
                                        'is-invalid': $v.agent.code.$error,
                                    }" :disabled="agent.id?true: false" :readonly="agent.id?true: false" />
                                <div v-if="$v.agent.code.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.code.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-2">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Code</label>
                                <input type="text" class="form-control" v-model="agent.LWA_userName" 
                                    placeholder="Enter Code" :class="{
                                        'is-invalid': $v.agent.LWA_userName.$error,
                                    }" :disabled="agent.id?true: false" :readonly="agent.id?true: false" />
                                <div v-if="$v.agent.LWA_userName.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.LWA_userName.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label for="contact-info-phone-input">Phone :</label>
                                <input type="text" class="form-control" v-model="agent.phone" id="contact-info-phone-input"
                                    placeholder="Enter phone" :class="{
                                        'is-invalid': $v.agent.phone.$error,
                                    }" />
                                <div v-if="$v.agent.phone.$error" class="invalid-feedback">
                                    <span v-if="!$v.agent.phone.required">This value is required.</span>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label for="choices-office-input" class="form-label">Office</label>
                                <select class="form-control" v-model="agent.office_code" @change="onOfficeChanged" name="choices-office-input" id="choices-office-input">
                                </select>
                            </div>
                        </div>


                    </div>

                    <div class="row">
                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">PM Code</label>
                                <input type="text" class="form-control" v-model="agent.property_mgt_code" 
                                    placeholder="Enter PM Code" />
                               
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Register Date</label>
                                <flat-pickr v-model="agent.fields.key_date.key_date_registration" placeholder="Select a date" :disabled="agent.id?true: false" :readonly="agent.id?true: false"
                                class="form-control"></flat-pickr>
                               
                            </div>
                        </div>

                        <div class="col-lg-4">
                            <div class="mb-3">
                                <label class="form-label" for="gen-info-name-input">Fee Run Date</label>
                                <flat-pickr v-model="agent.fields.key_date.key_date_fee_start" placeholder="Select a date"  :disabled="agent.id?true: false"
                                class="form-control"></flat-pickr>
                               
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>
        <!-- end card -->

        <div class="card border shadow-none mb-5">
            <div class="card-header d-flex align-items-center">
                <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                        <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                            02
                        </div>
                    </div>
                </div>
                <div class="flex-grow-1">
                    <h5 class="card-title">Contact Info</h5>
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-3">
                            <label for="contact-info-email-input">Email :</label>
                            <input type="email" class="form-control" v-model="agent.email" id="contact-info-email-input"
                                placeholder="Enter Email" />
                        </div>
                    </div>
                    <div class="col-md-6">

                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="mb-md-0">
                            <label for="contact-info-birthday-input" class="form-label">Birthday</label>
                            <flat-pickr v-model="agent.birthday" placeholder="Select a date"
                                class="form-control"></flat-pickr>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="mb-md-0">
                            <label for="contact-info-location-input" class="form-label">Location</label>
                            <input type="text" class="form-control" v-model="agent.address" placeholder="Enter Address"
                                id="contact-info-location-input" />
                        </div>
                    </div>
                </div>


                <div class="mb-3">
                    <label class="form-label" for="gen-info-description-input">Description</label>
                    <textarea class="form-control" placeholder="Enter Description" id="gen-info-description-input"
                        rows="3"></textarea>
                </div>
            </div>
        </div>
        <!-- end card -->

    </div>
</template>